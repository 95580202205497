import styles from './login.module.scss'
import classNames from 'classnames'
import Menu from '@/assets/icons/menu.svg'
import ProfileIcon from '@/assets/icons/user-circle.svg'
import Bell from '@/assets/icons/bell.svg'
import Link from 'next/link'
import Logo from '@/assets/logo.svg'
import { Profile } from '@/models/profile'
import { Image } from '@/components/commons/image'
import { useMemo } from 'react'

type Props = {
  onClickMenu: () => void
  onClickNews: () => void
  onClickProfile: () => void
  hasUnreadNews: boolean
  profile?: Profile
  isShowProfileIconBadge: boolean
}

export const HeaderLogin = ({ onClickMenu, onClickNews, onClickProfile, hasUnreadNews, profile, isShowProfileIconBadge }: Props) => {
  const newsClasses = classNames(styles.news, {
    [styles.badge]: hasUnreadNews,
  })

  const profileIconClasses = classNames(styles.profileButton, {
    [styles.badge]: isShowProfileIconBadge,
  })

  const handleClickMenu = () => {
    onClickMenu()
  }

  const handleClickNews = () => {
    onClickNews()
  }

  const handleClickProfile = () => {
    onClickProfile()
  }

  const profileImageElement = useMemo(() => {
    if (!profile || !profile.iconImage) return <ProfileIcon width={24} height={24} />
    else
      return (
        <div className={styles.profileIconImage}>
          <Image src={profile.iconImage} width={24} height={24} alt={profile.nickname} />
        </div>
      )
  }, [profile])

  return (
    <div className={styles.header_login}>
      <button className={styles.menu} onClick={handleClickMenu}>
        <Menu width={24} height={24} />
      </button>
      <div className={styles.logo}>
        <Link href="/">
          <a>
            <Logo height={14} />
          </a>
        </Link>
      </div>
      <div className={styles.rightLinks}>
        <button className={newsClasses} onClick={handleClickNews}>
          <Bell width={24} height={24} />
        </button>
        <button className={profileIconClasses} onClick={handleClickProfile}>
          {profileImageElement}
        </button>
      </div>
    </div>
  )
}
