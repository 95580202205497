import styles from './index.module.scss'
import { useCallback, useMemo } from 'react'
import { useGlobalNavigation } from '@/hooks/use-global-navigation'
import { useNewsList } from '@/hooks/use-news-list'
import { useProfileMenu } from '@/hooks/use-profile-menu'
import { useSiteData } from '@/hooks/use-site-data'
import { HeaderLogout } from '@/components/layouts/header-default/logout'
import { HeaderLogin } from '@/components/layouts/header-default/login'
import { ProfileMenu } from '@/components/root/profile-menu'

export const Header = () => {
  const { isCreateUser, isSetBirthday, hasWallet, hasUnreadNews, isDisplayedUserIconBadge, profile, setDisplayUserIconBadgeStatus } = useSiteData()
  const { showGlobalNavigation } = useGlobalNavigation()
  const { showNewsList } = useNewsList()
  const { showProfileMenu } = useProfileMenu()

  const isShowProfileIconBadge = useMemo<boolean>(() => {
    return !isDisplayedUserIconBadge && !profile?.iconImage
  }, [isDisplayedUserIconBadge, profile?.iconImage])

  const handleClickMenu = useCallback(() => {
    showGlobalNavigation()
  }, [showGlobalNavigation])

  const handleClickNews = useCallback(() => {
    showNewsList()
  }, [showNewsList])

  const handleClickProfile = useCallback(() => {
    if (!isDisplayedUserIconBadge) {
      setDisplayUserIconBadgeStatus()
    }
    showProfileMenu()
  }, [isDisplayedUserIconBadge, showProfileMenu, setDisplayUserIconBadgeStatus])

  let contentComponent: JSX.Element = <HeaderLogout onClickMenu={handleClickMenu} />
  if (isCreateUser && isSetBirthday && hasWallet) {
    contentComponent = <HeaderLogin onClickMenu={handleClickMenu} onClickNews={handleClickNews} onClickProfile={handleClickProfile} hasUnreadNews={hasUnreadNews} profile={profile} isShowProfileIconBadge={isShowProfileIconBadge} />
  }

  return (
    <header className={styles.header}>
      {contentComponent}
      <ProfileMenu />
    </header>
  )
}
