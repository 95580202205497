import styles from './index.module.scss'
import Link from 'next/link'
import { useSiteData } from '@/hooks/use-site-data'
import { useProfileMenu } from '@/hooks/use-profile-menu'
import { useCallback, useMemo } from 'react'
import NewIcon from '@/assets/profile/new-badge.svg'
import PointIcon from '@/assets/icons/point.svg'

export const ProfileMenu = () => {
  const { user, isDisplayedProfileNewIcon, profile, setDisplayProfileNewIconStatus } = useSiteData()
  const { isShowProfileMenu } = useProfileMenu()

  let menuElement: JSX.Element | null = null

  const newIcon = useMemo<JSX.Element | undefined>(() => {
    if (!profile?.iconImage && !isDisplayedProfileNewIcon) {
      return <NewIcon width={35} height={16} />
    }
  }, [isDisplayedProfileNewIcon, profile?.iconImage])

  const handleClickProfile = useCallback(() => {
    if (isDisplayedProfileNewIcon) return
    setDisplayProfileNewIconStatus()
  }, [isDisplayedProfileNewIcon, setDisplayProfileNewIconStatus])

  if (isShowProfileMenu && user) {
    menuElement = (
      <div className={styles.header_sub_menu}>
        <ul className={styles.list}>
          <li className={styles.item} onClick={handleClickProfile}>
            <Link href={`/user/${user.userName}`}>
              <a>
                <span className={styles.nickname}>{user.nickname}</span>
                <div className={styles.profile}>
                  <span className={styles.link}>プロフィールを表示</span>
                  {newIcon}
                </div>
              </a>
            </Link>
          </li>
          <li className={styles.points}>
            <Link href={'/points/history'}>
              <a>
                <div className={styles.points__inner}>
                  <PointIcon width={24} height={24} />
                  保有ポイントを確認
                </div>
              </a>
            </Link>
          </li>
          <li className={styles.item}>
            <Link href={`/cards/user/${user.userName}`}>
              <a>保有NFTカード</a>
            </Link>
          </li>
          <li className={styles.item}>
            <Link href={'/entries'}>
              <a>購入申込履歴</a>
            </Link>
          </li>
          <li className={styles.item}>
            <Link href={'/settings'}>
              <a>アカウント設定</a>
            </Link>
          </li>
          <li className={styles.item}>
            <Link href={'/promotion-code'}>
              <a>プロモーションコード</a>
            </Link>
          </li>
        </ul>
      </div>
    )
  }
  return menuElement
}
