import styles from './index.module.scss'

export const Footer = () => {
  return (
    <footer>
      <p className={styles.copyright}>
        <small>Copyright © KAWASAKI BRAVE THUNDERS . All Rights Reserved.</small>
      </p>
    </footer>
  )
}
