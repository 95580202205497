import styles from './default.module.scss'
import { Header } from '@/components/layouts/header-default'
import { Footer } from '@/components/layouts/footer-default'
import { MatchingBanner } from '@/components/layouts/matching-banner'
import { useProfileMenu } from '@/hooks/use-profile-menu'
import { Game } from '@/models/game'
import { useMemo } from 'react'
import { isDesktop } from 'react-device-detect'
import { DesktopBackground } from './desktop-background'

type Props = {
  children: React.ReactNode
  matchingGame?: Game
}

export const LayoutDefault = ({ children, matchingGame }: Props) => {
  const { isShowProfileMenu, hideProfileMenu } = useProfileMenu()

  const handleClickLayout = () => {
    if (isShowProfileMenu) {
      hideProfileMenu()
    }
  }

  const matchingBarComponent = useMemo<JSX.Element | undefined>(() => {
    if (!matchingGame) return

    const hasDeck = matchingGame.gameDeck !== undefined
    if (hasDeck) return <MatchingBanner game={matchingGame} />
  }, [matchingGame])

  return (
    <>
      <div className={styles.layout} onClick={handleClickLayout}>
        {matchingBarComponent}
        <Header />
        <main className={styles.main}>{children}</main>
        <Footer />
      </div>
      {isDesktop ? <DesktopBackground /> : null}
    </>
  )
}
