import styles from './logout.module.scss'
import Menu from '@/assets/icons/menu.svg'
import Logo from '@/assets/logo.svg'

type Props = {
  onClickMenu: () => void
}

export const HeaderLogout = ({ onClickMenu }: Props) => {
  const handleClickMenu = () => {
    onClickMenu()
  }

  return (
    <div className={styles.header_logout}>
      <button className={styles.menu} onClick={handleClickMenu}>
        <Menu width={24} height={24} />
      </button>
      <h1 className={styles.logo}>
        <Logo width={105} height={14} />
      </h1>
    </div>
  )
}
