import styles from './index.module.scss'
import { Game } from '@/models/game'
import { useMemo } from 'react'
import { useRouter } from 'next/router'

type Props = {
  game: Game
}

export const MatchingBanner = ({ game }: Props) => {
  const router = useRouter()
  const title = useMemo<string>(() => {
    if (game.title.length <= 18) {
      return game.title
    } else {
      const parseTitle = game.title.slice(0, 17) + '…'
      return parseTitle
    }
  }, [game.title])

  const matchingBarElement = useMemo<JSX.Element>(() => {
    return (
      <div className={styles.matching} onClick={() => router.push(`/games/${game.id}/matching`)}>
        <span>試合中</span>
        {title}に参加しよう！
      </div>
    )
  }, [game.id, router, title])

  return <>{matchingBarElement}</>
}
